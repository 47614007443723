import axios from '@axios'

export default {
  namespaced: true,
  state: {
    merchants: [],
  },
  getters: {},
  mutations: {},
  actions: {
    // 获取注册列表数据
    fetchRegistrationList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/open-account/list?page_num=${params.page_num}&page_size=${params.page_size}`, params.data, {
          headers: {
            'content-type': 'application/json',
          },
        })
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    fetchDropdownList() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/open-account/dropdown-list')
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    registrationUploadLogo(ctx, params) {
      const formData = new FormData()
      formData.append('id', params.id)
      formData.append('logo', params.file)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/merchant/open-account/upload-logo', formData, config)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    openAccountUpdate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/open-account/update', JSON.stringify(params), {
          headers: {
            'content-type': 'application/json',
          },
        }).then(response => {
          if (response.data.code === '10000') {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => { reject(error) })
      })
    },
    createMerchantPlatform(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/open-account/create-merchant-platform', JSON.stringify(params), {
          headers: {
            'content-type': 'application/json',
          },
        }).then(response => {
          if (response.data.code === '10000') {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => { reject(error) })
      })
    },
  },
}
