<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <!-- radio -->
          <b-form-radio-group
            v-model="reviewStatusFilter"
            class="ml-1 list item-view-radio-group"
            buttons
            button-variant="outline-primary"
            @input="fastSelected"
          >
            <b-form-radio value="0">
              All
            </b-form-radio>
            <b-form-radio value="1">
              Pending Review
            </b-form-radio>
            <b-form-radio value="2">
              Reviewed
            </b-form-radio>
          </b-form-radio-group>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      opacity="0.7"
      rounded="sm"
    >
      <b-card no-body>
        <b-table
          ref="refRegistrationTable"
          class="position-relative"
          responsive
          :fields="registrationTableFields"
          :items="fetchListRegistration"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="trade_no"
          show-empty
          striped
          :borderless="false"
          empty-text="No matching records found"
          @filtered="onFiltered"
        >
          // eslint-disable-next-line vue/no-parsing-error
          <template #cell(country)="data">
            <div>
              {{ countryText(data.item.basic_info.country) }}
            </div>
          </template>
          <template #cell(review_status)="data">
            <div>
              {{ reviewStatusText(data.item.review_status, data.item.status) }}
            </div>
          </template>
          <template #cell(solution)="data">
            <div>
              {{ solutionText(data.item.solution) }}
            </div>
          </template>
          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              @click="registrationDetail(data.item)"
            >
              Detail
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRegistration"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="paginationChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <!-- :review-status="currentReviewStatus" -->
    <b-sidebar
      id="sidebar-registration-tobereviewed"
      ref="sidebar-registration-tobereviewed"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetSidebaring"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Registration Review
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <registration-details-tobereviewed
          ref="tobereviewed-component"
          :registration-info="registrationInfo"
          :config-set="configSet"
          :account-status-list.sync="accountStatusList"
          :company-size-list.sync="companySizeList"
          :currency-list.sync="currencyList"
          :industry-list.sync="industryList"
          :merchant-tag-list.sync="merchantTagList"
          :merchant-type-list.sync="merchantTypeList"
          :phone-code-list.sync="phoneCodeList"
          :region-list.sync="regionList"
          :solution-list.sync="solutionList"
          :trade-status-list.sync="tradeStatusList"
          :transaction-volume-list.sync="transactionVolumeList"
          @confirm="toBeReviewedConfirm(hide)"
        />
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-registration-reviewed"
      ref="sidebar-registration-reviewed"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetSidebared"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Registration Detail
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>
        <registration-details-reviewed
          ref="reviewed-component"
          :registration-info="registrationInfo"
          :config-set="configSet"
          :account-status-list.sync="accountStatusList"
          :company-size-list.sync="companySizeList"
          :currency-list.sync="currencyList"
          :industry-list.sync="industryList"
          :merchant-tag.sync="merchantTagList"
          :merchant-type.sync="merchantTypeList"
          :region-list.sync="regionList"
          :solution-list.sync="solutionList"
          :trade-status-list.sync="tradeStatusList"
          :transaction-volume-list.sync="transactionVolumeList"
          @confirm="toBeReviewedConfirm(hide)"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BCard, BRow, BCol, BTable, BPagination, BFormRadioGroup, BFormRadio, BCardBody, BButton, VBToggle, BOverlay,
} from 'bootstrap-vue'
import { defineComponent, getCurrentInstance } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import RegistrationDetailsTobereviewed from './RegistrationDetailsTobereviewed.vue'
import RegistrationDetailsReviewed from './RegistrationDetailsReviewed.vue'
import useRegistrationListView from './useRegistrationListView'

export default defineComponent({
  components: {
    BSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,

    BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BOverlay,
    RegistrationDetailsTobereviewed,
    RegistrationDetailsReviewed,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const {
      isLoading,
      // table
      refRegistrationTable,
      currentPage,
      perPage,
      dataMeta,
      totalRegistration,
      // registration,
      registrationTableFields,
      fastSelected,
      reviewStatusFilter,
      paginationChange,
      onFiltered,
      registrations,
      // detail
      currentReviewStatus,
      tzSelected,
      registrationInfo,
      configSet,
      // func
      fetchListRegistration,
      dateFormat,
      registrationDetail,
      // Dropdown List
      accountStatusList,
      companySizeList,
      currencyList,
      industryList,
      merchantTagList,
      merchantTypeList,
      phoneCodeList,
      regionList,
      reviewStatusList,
      solutionList,
      tradeStatusList,
      transactionVolumeList,
    } = useRegistrationListView()
    const { proxy } = getCurrentInstance()
    const toBeReviewedConfirm = hide => {
      // reviewStatusFilter.value = 0
      currentPage.value = 1
      perPage.value = 10
      hide()
      refRegistrationTable.value.refresh()
      // fetchListRegistration()
    }
    const resetSidebaring = () => {
      proxy.$refs['tobereviewed-component'].$refs.tobeReviewRules.reset()
      proxy.$refs['tobereviewed-component'].logoUrl = ''
    }
    const resetSidebared = () => {
      proxy.$refs['reviewed-component'].$refs.reviewRules.reset()
      proxy.$refs['reviewed-component'].logoUrl = ''
    }
    const countryText = country => {
      const currentRegion = regionList.value.filter(region => region.code === country)
      if (currentRegion[0]) {
        return currentRegion[0].desc
      }
      return ''
    }
    const reviewStatusText = (reviewStatus, status) => {
      const currentStatus = reviewStatusList.value.filter(account => account.code === Number(reviewStatus))
      if (reviewStatus === '1' && currentStatus[0]) {
        return currentStatus[0].desc
      }
      if (reviewStatus === '2' && status === '110') {
        return 'Approved'
      }
      if (reviewStatus === '2' && status === '120') {
        return 'Rejected'
      }
      return ''
    }
    const solutionText = solution => {
      const solutions = solutionList.value.filter(value => solution.includes(`${value.code}`))
      if (solutions.length === 2) {
        return 'Payins & Payouts'
      }
      if (solutions.length === 1) {
        return solutions[0].desc
      }
      return ''
    }
    return {
      isLoading,
      // table
      refRegistrationTable,
      currentPage,
      perPage,
      dataMeta,
      totalRegistration,
      // registration,
      registrationTableFields,
      fastSelected,
      reviewStatusFilter,
      paginationChange,
      onFiltered,
      registrations,
      // detail
      currentReviewStatus,
      tzSelected,
      registrationInfo,
      configSet,
      // func
      fetchListRegistration,
      dateFormat,
      registrationDetail,
      toBeReviewedConfirm,
      resetSidebaring,
      resetSidebared,
      // Dropdown List
      accountStatusList,
      companySizeList,
      currencyList,
      industryList,
      merchantTagList,
      merchantTypeList,
      phoneCodeList,
      regionList,
      reviewStatusList,
      solutionList,
      tradeStatusList,
      transactionVolumeList,
      // 处理table文案
      countryText,
      reviewStatusText,
      solutionText,
    }
  },
})

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.td-merchant-no {
  display: inline-block!important;
}
.td-merchant-no:hover {
  text-decoration: underline;
}
</style>
