import { getCurrentInstance, ref } from '@vue/composition-api'
import store from '@/store'
import registrationStoreModule from '../../registrationStoreModule'

export default function useRegistrationListView() {
  const REGISTRATION_STORE_MODULE_NAME = 'registration'
  // Register module
  if (!store.hasModule(REGISTRATION_STORE_MODULE_NAME)) store.registerModule(REGISTRATION_STORE_MODULE_NAME, registrationStoreModule)

  const { proxy } = getCurrentInstance()
  const isReviewedLoading = ref(false)
  const isToBeReviewedLoading = ref(false)
  // upload image
  const logoUrl = ref('')
  const inputImageRenderer = input => {
    if (input.target.files && input.target.files[0]) {
      if (input.target.files[0].size / 1024 > 200) { // 64
        proxy.$bvToast.toast('File is too large, Please control the file size within 200k.', {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          solid: true,
        })
        return
      }
      const response = store
        .dispatch('registration/registrationUploadLogo', {
          id: proxy.registrationInfo.id,
          file: input.target.files[0],
        })
      response.then(res => {
        if (res.data.code === '10000') {
          logoUrl.value = res.data.data
        } else {
          proxy.$bvToast.toast(`${res.data.msg}`, {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
        }
      }).catch(() => {
        proxy.$bvToast.toast('Error fetching Registration', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
    }
  }

  const createMerchantPlatform = (params, callback) => {
    const response = store.dispatch('registration/createMerchantPlatform', { id: params.id })
    response.then(() => {
      proxy.$bvToast.toast('Success', {
        title: 'Success',
        icon: 'AlertTriangleIcon',
        variant: 'success',
        toaster: 'b-toaster-top-right',
        solid: true,
      })
      // eslint-disable-next-line no-unused-expressions
      callback && callback()
      isReviewedLoading.value = false
      isToBeReviewedLoading.value = false
    }).catch(res => {
      proxy.$bvToast.toast(`${res.data.sub_msg}`, {
        title: 'Error',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        toaster: 'b-toaster-top-right',
        solid: true,
      })
      // eslint-disable-next-line no-unused-expressions
      callback && callback()
      isReviewedLoading.value = false
      isToBeReviewedLoading.value = false
    })
  }
  // comfirm data
  const openAccountUpdate = (params, callback) => {
    isReviewedLoading.value = true
    isToBeReviewedLoading.value = true
    const response = store.dispatch('registration/openAccountUpdate', params)
    response.then(res => {
      if (res.data.code === '10000') {
        // 如果review status为 approved 创建账号
        if (params.status === '110') {
          createMerchantPlatform(params, callback)
        } else {
          proxy.$bvToast.toast('Update Success', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
          // eslint-disable-next-line no-unused-expressions
          callback && callback()
          isReviewedLoading.value = false
          isToBeReviewedLoading.value = false
        }
      } else {
        proxy.$bvToast.toast(`${res.data.msg}`, {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      }
    }).catch(() => {
      isReviewedLoading.value = false
      isToBeReviewedLoading.value = false
      proxy.$bvToast.toast('Error fetching Registration', {
        title: 'Error',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        toaster: 'b-toaster-top-right',
        solid: true,
      })
    })
  }

  return {
    inputImageRenderer,
    logoUrl,
    openAccountUpdate,
    isReviewedLoading,
    isToBeReviewedLoading,
  }
}
