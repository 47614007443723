<template>
  <b-overlay
    :show="isReviewedLoading"
    spinner-variant="primary"
    variant="light"
    opacity="0.7"
    rounded="sm"
  >
    <validation-observer ref="reviewRules">
      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <div v-if="logoUrl">
          <b-button
            v-if="!logoUrl"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="logoUrl"
            thumbnail
            fluid
            center
            :src="logoUrl"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >
        </div>
        <div v-if="!logoUrl">
          <b-button
            v-if="!registrationInfo.logo_url"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="registrationInfo.logo_url"
            thumbnail
            fluid
            center
            :src="registrationInfo.logo_url"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >
        </div>

        <!-- ID -->
        <b-form-group
          label="ID"
          label-for="ID"
        >
          <b-form-input
            id="ID"
            v-model="registrationInfo.merchant_no"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Create Time -->
        <b-form-group
          label="Create Time"
          label-for="createTime"
        >
          <b-form-input
            id="createTime"
            v-model="registrationInfo.create_time"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Name -->
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="registrationInfo.company_name"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Country -->
        <b-form-group
          label="Country"
          label-for="country"
        >
          <v-select
            id="country"
            v-model="registrationInfo.country"
            label="desc"
            :options="regionList"
            :clearable="false"
            class="w-100"
            :reduce="val => val.code"
            :disabled="inputFlag"
          />
        </b-form-group>

        <!-- Industry -->
        <b-form-group
          label="Industry"
          label-for="industry"
        >
          <v-select
            id="industry"
            v-model="registrationInfo.industry"
            label="desc"
            :options="industryList"
            :clearable="false"
            class="w-100"
            :reduce="val => val.code"
            :disabled="inputFlag"
          />
        </b-form-group>

        <!-- Contact Name -->
        <b-form-group
          label="Contact Name"
          label-for="contactName"
        >
          <b-form-input
            id="contactName"
            v-model="registrationInfo.full_name"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Contact Email -->
        <b-form-group
          label="Contact Email"
          label-for="contactEmail"
        >
          <b-form-input
            id="contactEmail"
            v-model="registrationInfo.business_email"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Contact Phone -->
        <b-form-group
          label="Contact Phone"
          label-for="contactPhone"
        >
          <b-form-input
            id="contactPhone"
            v-model="registrationInfo.phone_number"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Company Website -->
        <b-form-group
          label="Company Website"
          label-for="companyWebsite"
        >
          <b-form-input
            id="companyWebsite"
            v-model="registrationInfo.company_website"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Company Size -->
        <b-form-group
          label="Company Size"
          label-for="companySize"
        >
          <b-form-input
            id="companySize"
            v-model="registrationInfo.company_size"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Annual Global Transaction Volume -->
        <b-form-group
          label="Annual Global Transaction Volume"
          label-for="annualGlobalTransactionVolume"
        >
          <b-form-input
            id="annualGlobalTransactionVolume"
            v-model="registrationInfo.global_transaction_volume"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- LATAM Transaction Volume -->
        <b-form-group
          label="LATAM Transaction Volume"
          label-for="LATAMTransactionVolume"
        >
          <b-form-input
            id="LATAMTransactionVolume"
            v-model="registrationInfo.latam_transaction_volume"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <!-- Plan to Use Pagsmile Payment Services in -->
        <b-form-group
          label="Plan to Use Pagsmile Payment Services in"
          label-for="paymentServicesIn"
        >
          <b-form-textarea
            id="paymentServicesIn"
            v-model="regionText"
            rows="6"
            trim
            wrap="hard"
            :disabled="inputFlag"
          />
        </b-form-group>

        <!-- Need Solution -->
        <b-form-group
          label="Need Solution"
          label-for="needSolution"
        >
          <v-select
            id="needSolution"
            v-model="registrationInfo.need_solution"
            multiple
            label="desc"
            :options="solutionList"
            :reduce="val => val.code"
            :close-on-select="inputFlag"
          />
        </b-form-group>

        <!-- Message -->
        <b-form-group
          label="Message"
          label-for="paymentServicesIn"
        >
          <b-form-textarea
            id="paymentServicesIn"
            v-model="registrationInfo.demand"
            rows="4"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <el-divider class="mt-3 mb-3" />

        <!-- Review Status -->
        <b-form-group
          label="Review Status"
          label-for="reviewStatus"
        >
          <v-select
            id="reviewStatus"
            v-model="configSet.reviewStatus"
            :options="accountStatusList"
            :clearable="false"
            class="w-100"
            label="desc"
            :reduce="val => val.code"
            :disabled="inputFlag"
          />
        </b-form-group>

        <!-- Solution -->
        <validation-provider
          #default="{ errors }"
          name="Solution"
          rules="required"
        >
          <b-form-group
            label="Solution"
            label-for="solution"
          >
            <b-form-checkbox-group>
              <p>
                <b-form-checkbox
                  v-model="registrationInfo.solution"
                  value="1"
                  :state="errors.length > 0 ? false : null"
                >
                  Payins - Accept local payment methods
                </b-form-checkbox>
              </p>
              <p>
                <b-form-checkbox
                  v-model="registrationInfo.solution"
                  value="2"
                  :state="errors.length > 0 ? false : null"
                >
                  Payouts - Orchestrate local payouts
                </b-form-checkbox>
              </p>
            </b-form-checkbox-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Update Time -->
        <b-form-group
          label="Update Time"
          label-for="updateTime"
        >
          <b-form-input
            id="updateTime"
            v-model="registrationInfo.update_time"
            :disabled="inputFlag"
            trim
          />
        </b-form-group>

        <el-divider class="mt-3 mb-1" />

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="confirm"
          >
            Confirm
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox, BImg, BFormCheckboxGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  defineComponent, computed, getCurrentInstance, ref,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useRegistrationSidebarDetails from './useRegistrationSidebarDetails'

export default defineComponent({
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox, vSelect, BImg, ValidationProvider, ValidationObserver, BFormCheckboxGroup, BOverlay,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const {
      logoUrl,
      inputImageRenderer,
      openAccountUpdate,
      isReviewedLoading,
    } = useRegistrationSidebarDetails()
    const inputFlag = ref(true)
    // Plan to Use Pagsmile Payment Services in/text
    const regionText = computed(() => {
      let text = ''
      const { region } = proxy.registrationInfo
      proxy.regionList.forEach(value => {
        if (region && region.some(r => r === value.code)) {
          text += `${value.desc}\n`
        }
      })
      return text
    })
    const confirm = () => {
      const info = proxy.registrationInfo
      proxy.$refs.reviewRules.validate().then(success => {
        // console.log('=================', success)
        if (success) {
          const params = { id: info.id, solution: info.solution, logo_url: info.logo_url }
          console.log('reviewed params = ', params)
          // 保存数据
          openAccountUpdate(params, () => {
            // 关闭sidebar
            proxy.$emit('confirm')
          })
        }
      })
    }
    return {
      logoUrl,
      inputImageRenderer,
      isReviewedLoading,
      regionText,
      inputFlag,
      // func
      confirm,
      required,
    }
  },
  props: {
    registrationInfo: {
      type: Object,
      default: () => {},
    },
    configSet: {
      type: Object,
      default: () => {},
    },
    // Review Status
    accountStatusList: {
      type: Array,
      default: () => [],
    },
    companySizeList: {
      type: Array,
      default: () => [],
    },
    currencyList: {
      type: Array,
      default: () => [],
    },
    industryList: {
      type: Array,
      default: () => [],
    },
    merchantTagList: {
      type: Array,
      default: () => [],
    },
    merchantTypeList: {
      type: Array,
      default: () => [],
    },
    regionList: {
      type: Array,
      default: () => [],
    },
    solutionList: {
      type: Array,
      default: () => [],
    },
    // ipn
    tradeStatusList: {
      type: Array,
      default: () => [],
    },
    transactionVolumeList: {
      type: Array,
      default: () => [],
    },
  },
})
</script>

<style>

</style>
