<template>
  <b-overlay
    :show="isToBeReviewedLoading"
    spinner-variant="primary"
    variant="light"
    opacity="0.7"
    rounded="sm"
  >
    <!-- Body -->
    <validation-observer ref="tobeReviewRules">
      <b-form
        class="p-2"
        @submit.prevent
      >
        <div v-if="logoUrl">
          <b-button
            v-if="!logoUrl"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="logoUrl"
            thumbnail
            fluid
            center
            :src="logoUrl"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >
        </div>
        <div v-if="!logoUrl">
          <b-button
            v-if="!registrationInfo.logo_url"
            variant="primary"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          >
            Upload LOGO
          </b-button>

          <b-img
            v-if="registrationInfo.logo_url"
            thumbnail
            fluid
            center
            :src="registrationInfo.logo_url"
            alt="Image"
            class="mb-2"
            @click="$refs.refInputEl.click()"
          />
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @change="inputImageRenderer"
          >
        </div>

        <!-- ID -->
        <!-- <validation-provider
        #default="{ errors }"
        name="ID"
        rules="required"
      > -->
        <b-form-group
          label="ID"
          label-for="ID"
        >
          <!-- :state="errors.length > 0 ? false : null" -->
          <!-- v-model="registrationInfo.id" -->
          <b-form-input
            id="ID"
            trim
            :disabled="true"
          />
        <!-- <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback> -->
        </b-form-group>
        <!-- </validation-provider> -->

        <!-- Create Time -->
        <validation-provider
          #default="{ errors }"
          name="Create Time"
          rules="required"
        >
          <b-form-group
            label="Create Time"
            label-for="createTime"
          >
            <date-picker
              id="createTime"
              v-model="registrationInfo.create_time"
              value-type="format"
              format="YYYY-MM-DD HH:mm:ss"
              type="datetime"
              confirm
              :clearable="false"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Name -->
        <validation-provider
          #default="{ errors }"
          name="Name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="Name"
          >
            <b-form-input
              id="Name"
              v-model="registrationInfo.company_name"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Industry -->
        <validation-provider
          #default="{ errors }"
          name="Industry"
          rules="required"
        >
          <b-form-group
            label="Industry"
            label-for="industry"
          >
            <v-select
              id="industry"
              v-model="registrationInfo.industry"
              label="desc"
              :options="industryList"
              :clearable="false"
              class="w-100"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Type -->
        <b-form-group
          label="Type"
          label-for="type"
        >
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <v-select
              id="type"
              v-model="registrationInfo.type"
              label="desc"
              :options="merchantTypeList"
              :clearable="false"
              class="w-100"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Tag -->
        <validation-provider
          #default="{ errors }"
          name="Tag"
          rules="required"
        >
          <b-form-group
            label="Tag"
            label-for="tag"
          >
            <v-select
              id="tag"
              v-model="registrationInfo.tag"
              label="desc"
              :options="merchantTagList"
              :clearable="false"
              class="w-100"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Contact Name -->
        <validation-provider
          #default="{ errors }"
          name="Contact Name"
          rules="required"
        >
          <b-form-group
            label="Contact Name"
            label-for="contactName"
          >
            <b-form-input
              id="contactName"
              v-model="registrationInfo.full_name"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Contact Email -->
        <validation-provider
          #default="{ errors }"
          name="Contact Email"
          rules="required"
        >
          <b-form-group
            label="Contact Email"
            label-for="contactEmail"
          >
            <b-form-input
              id="contactEmail"
              v-model="registrationInfo.business_email"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Contact Phone -->
        <validation-provider
          #default="{ errors }"
          name="Contact Phone"
          rules="required"
        >
          <b-form-group
            label="Contact Phone"
            label-for="contactPhone"
          >
            <b-form-input
              id="contactPhone"
              v-model="registrationInfo.phone_number"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Country -->
        <validation-provider
          #default="{ errors }"
          name="Country"
          rules="required"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <v-select
              id="country"
              v-model="registrationInfo.country"
              label="desc"
              :options="regionList"
              :clearable="false"
              class="w-100"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Location -->
        <validation-provider
          #default="{ errors }"
          name="Location"
          rules="required"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <b-form-input
              id="location"
              v-model="registrationInfo.location"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Area Code -->
        <validation-provider
          #default="{ errors }"
          name="Area Code"
          rules="required"
        >
          <b-form-group
            label=""
            label-for=""
          >
            <!-- phoneCodeList -->
            <b-form-group
              label="Area Code"
              label-for="areaCode"
            >
              <v-select
                id="areaCode"
                v-model="registrationInfo.area_code"
                :options="phoneCodeList"
                :clearable="false"
                class="w-100"
                label="code"
                :reduce="val => val.code"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </b-form-group>
        </validation-provider>

        <!-- Company Website -->
        <validation-provider
          #default="{ errors }"
          name="Company Website"
          rules="required"
        >
          <b-form-group
            label="Company Website"
            label-for="companyWebsite"
          >
            <b-form-input
              id="companyWebsite"
              v-model="registrationInfo.company_website"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Company Size -->
        <validation-provider
          #default="{ errors }"
          name="Company Size"
          rules="required"
        >
          <b-form-group
            label="Company Size"
            label-for="companySize"
          >
            <v-select
              id="companySize"
              v-model="registrationInfo.company_size"
              :options="companySizeList"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Annual Global Transaction Volume -->
        <validation-provider
          #default="{ errors }"
          name="Annual Global Transaction Volume"
          rules="required"
        >
          <b-form-group
            label="Annual Global Transaction Volume"
            label-for="annualGlobalTransactionVolume"
          >
            <v-select
              id="annualGlobalTransactionVolume"
              v-model="registrationInfo.global_transaction_volume"
              :options="transactionVolumeList"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- LATAM Transaction Volume -->
        <validation-provider
          #default="{ errors }"
          name="LATAM Transaction Volume"
          rules="required"
        >
          <b-form-group
            label="LATAM Transaction Volume"
            label-for="LATAMTransactionVolume"
          >
            <v-select
              id="LATAMTransactionVolume"
              v-model="registrationInfo.latam_transaction_volume"
              :options="transactionVolumeList"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Plan to Use Pagsmile Payment Services in -->
        <validation-provider
          #default="{ errors }"
          name="Plan to Use Pagsmile Payment Services in"
          rules="required"
        >
          <b-form-group
            label="Plan to Use Pagsmile Payment Services in"
            label-for="paymentServicesIn"
          >
            <v-select
              id="paymentServicesIn"
              v-model="registrationInfo.region"
              label="desc"
              :options="regionList"
              :clearable="false"
              class="w-100"
              multiple
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Need Solution -->
        <validation-provider
          #default="{ errors }"
          name="Need Solution"
          rules="required"
        >
          <b-form-group
            label="Need Solution"
            label-for="needSolution"
          >
            <v-select
              id="needSolution"
              v-model="registrationInfo.need_solution"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="desc"
              :options="solutionList"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Message -->
        <validation-provider
          #default="{ errors }"
          name="Message"
          rules="required"
        >
          <b-form-group
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="registrationInfo.demand"
              rows="4"
              trim
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <el-divider class="mt-3 mb-3" />

        <!-- IPN Status -->
        <validation-provider
          #default="{ errors }"
          name="IPN Status"
          rules="required"
        >
          <b-form-group
            label="IPN Status"
            label-for="IPNStatus-1"
          >
            <v-select
              id="IPNStatus-1"
              v-model="configSet.ipnStatus"
              :options="tradeStatusList"
              :clearable="false"
              class="w-100"
              multiple
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <el-divider class="mt-3 mb-3" />

        <!-- Balance Visible -->
        <validation-provider
          #default="{ errors }"
          name="Balance Visible"
          rules="required"
        >
          <b-form-group
            label="Balance Visible"
            label-for="balanceVisible"
          >
            <v-select
              id="balanceVisible"
              v-model="configSet.balanceVisible"
              :options="commonOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- APP Generates Bills Independently -->
        <validation-provider
          #default="{ errors }"
          name="APP Generates Bills Independently"
          rules="required"
        >
          <b-form-group
            label="APP Generates Bills Independently"
            label-for="APPGeneratesBillsIndependently"
          >
            <v-select
              id="APPGeneratesBillsIndependently"
              v-model="configSet.appGenerates"
              :options="commonOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Automatic Transfer to Payout Balance -->
        <validation-provider
          #default="{ errors }"
          name="Automatic Transfer to Payout Balance"
          rules="required"
        >
          <b-form-group
            label="Automatic Transfer to Payout Balance"
            label-for="AutomaticTransfertoPayoutBalance"
          >
            <v-select
              id="AutomaticTransfertoPayoutBalance"
              v-model="configSet.payoutBalance"
              :options="commonOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Settlement Exchange Rate -->
        <validation-provider
          #default="{ errors }"
          name="Settlement Exchange Rate"
          rules="required"
        >
          <b-form-group
            label="Settlement Exchange Rate"
            label-for="SettlementExchangeRate"
          >
            <v-select
              id="SettlementExchangeRate"
              v-model="configSet.exchangeRate"
              :options="exchangeRateOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Settlement Currency -->
        <validation-provider
          #default="{ errors }"
          name="Settlement Currency"
          rules="required"
        >
          <b-form-group
            label="Settlement Currency"
            label-for="SettlementCurrency"
          >
            <v-select
              id="SettlementCurrency"
              v-model="configSet.currency"
              :options="currencyList"
              :clearable="false"
              class="w-100"
              label="code"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Settlement Method -->
        <validation-provider
          #default="{ errors }"
          name="Settlement Method"
          rules="required"
        >
          <b-form-group
            label="Settlement Method"
            label-for="SettlementMethod"
          >
            <v-select
              id="SettlementMethod"
              v-model="configSet.method"
              :options="methodOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Settlement Reports Generation Cycle -->
        <validation-provider
          v-if="configSet.method === 'A'"
          #default="{ errors }"
          name="Settlement Method"
          rules="required"
        >
          <b-form-group
            label="Settlement Reports Generation Cycle"
            label-for="SettlementReportsGenerationCycle"
          >
            <!-- :state="errors.length > 0 ? false : null" -->
            <v-select
              id="SettlementReportsGenerationCycle"
              v-model="configSet.generationCycle"
              :options="settlementReportsOption"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
            />
            <b-input-group v-if="configSet.generationCycle === 'D'" prepend="Every" append="Day" class="mt-1">
              <b-form-input
                v-model="configSet.cycleEveryDay"
                type="number"
                trim
              />
            </b-input-group>
            <div v-if="configSet.generationCycle === 'W'" class="mt-1 generation-cycle">
              <v-select
                v-model="configSet.cycleWeekday"
                :options="weekdayOption"
                :clearable="false"
                class="w-100"
                label="desc"
                :reduce="val => val.code"
              />
              <b-input-group prepend="Every" append="Week">
                <b-form-input
                  v-model="configSet.cycleEveryWeek"
                  type="number"
                  trim
                />
              </b-input-group>
            </div>
            <div v-if="configSet.generationCycle === 'M'" class="mt-1 generation-cycle">
              <v-select
                v-model="configSet.cycleMonthDay"
                :options="monthOption"
                :clearable="false"
                class="w-100"
                label="desc"
                :reduce="val => val.code"
              />
              <b-input-group prepend="Every" append="Month">
                <b-form-input
                  v-model="configSet.cycleEveryMonth"
                  type="number"
                  trim
                />
              </b-input-group>
            </div>
            <div v-if="configSet.generationCycle === 'A'" class="mt-1 generation-cycle">
              <v-select
                v-model="configSet.amountType"
                :options="amountOption"
                :clearable="false"
                class="w-100"
                label="desc"
                :reduce="val => val.code"
              />
              <b-input-group prepend="Over" append="USD">
                <b-form-input
                  v-model="configSet.amountValue"
                  type="number"
                  trim
                />
              </b-input-group>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <el-divider class="mt-3 mb-3" />

        <!-- Review Status -->

        <b-form-group
          label="Review Status"
          label-for="reviewStatus"
        >
          <validation-provider
            #default="{ errors }"
            name="Review Status"
            rules="required"
          >
            <v-select
              id="reviewStatus"
              v-model="configSet.reviewStatus"
              :options="accountStatusList"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <b-form-group
          label="Status"
          label-for="defaultStatus"
        >
          <validation-provider
            #default="{ errors }"
            name="Status"
            rules="required"
          >
            <v-select
              id="defaultStatus"
              v-model="configSet.activationStatus"
              :options="statusOptions"
              :clearable="false"
              class="w-100"
              label="desc"
              :reduce="val => val.code"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->

        <!-- Solution -->
        <validation-provider
          #default="{ errors }"
          name="Solution"
          rules="required"
        >
          <b-form-group
            label="Solution"
            label-for="solution"
          >
            <b-form-checkbox-group>
              <p>
                <b-form-checkbox
                  v-model="registrationInfo.solution"
                  value="1"
                  :state="errors.length > 0 ? false : null"
                >
                  Payins - Accept local payment methods
                </b-form-checkbox>
              </p>
              <p>
                <b-form-checkbox
                  v-model="registrationInfo.solution"
                  value="2"
                  :state="errors.length > 0 ? false : null"
                >
                  Payouts - Orchestrate local payouts
                </b-form-checkbox>
              </p>
            </b-form-checkbox-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <el-divider class="mt-3 mb-1" />

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="confirm"
          >
            Confirm
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox, BImg, BFormInvalidFeedback, BInputGroup, BFormCheckboxGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  defineComponent, getCurrentInstance,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import useRegistrationSidebarDetails from './useRegistrationSidebarDetails'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const {
      logoUrl,
      inputImageRenderer,
      openAccountUpdate,
      isToBeReviewedLoading,
    } = useRegistrationSidebarDetails()
    const commonOptions = [
      { desc: 'Yes', code: 'Yes' },
      { desc: 'No', code: 'No' },
    ]
    const exchangeRateOptions = [
      { desc: 'By Transaction', code: 'Transaction' },
      { desc: 'By Settlement', code: 'Settlement' },
      { desc: 'By Bank', code: 'Bank' },
    ]
    const methodOptions = [
      { desc: 'Automatic', code: 'A' },
      { desc: 'Semi Automatic', code: 'SA' },
      { desc: 'Manual', code: 'M' },
    ]
    // status
    const statusOptions = [
      { desc: 'Disable', code: '0' },
      { desc: 'Enable', code: '1' },
    ]
    const settlementReportsOption = [
      { desc: 'By Day', code: 'D' },
      { desc: 'By Week', code: 'W' },
      { desc: 'By Month', code: 'M' },
      { desc: 'By Settleable Amount', code: 'A' },
    ]
    const weekdayOption = [
      { desc: 'Monday', code: '1' },
      { desc: 'Tuesday', code: '2' },
      { desc: 'Wednesday', code: '3' },
      { desc: 'Thursday', code: '4' },
      { desc: 'Friday', code: '5' },
      { desc: 'Saturday', code: '6' },
      { desc: 'Sunday', code: '7' },
    ]
    const monthOption = [
      { desc: '1st', code: '1' },
      { desc: '2nd', code: '2' },
      { desc: '3rd', code: '3' },
      { desc: '4th', code: '4' },
      { desc: '5th', code: '5' },
      { desc: '6th', code: '6' },
      { desc: '7th', code: '7' },
      { desc: '8th', code: '8' },
      { desc: '9th', code: '9' },
      { desc: '10th', code: '10' },
      { desc: '11th', code: '11' },
      { desc: '12th', code: '12' },
      { desc: '13th', code: '13' },
      { desc: '14th', code: '14' },
      { desc: '15th', code: '15' },
      { desc: '16th', code: '16' },
      { desc: '17th', code: '17' },
      { desc: '18th', code: '18' },
      { desc: '19th', code: '19' },
      { desc: '20th', code: '20' },
      { desc: '21st', code: '21' },
      { desc: '22nd', code: '22' },
      { desc: '23rd', code: '23' },
      { desc: '24th', code: '24' },
      { desc: '25th', code: '25' },
      { desc: '26th', code: '26' },
      { desc: '27th', code: '27' },
      { desc: '28th', code: '28' },
    ]
    const amountOption = [
      { desc: 'Of Order', code: '0' },
      { desc: 'Of Settle', code: '1' },
    ]
    // comfirm
    const confirm = () => {
      // console.log('registrationInfo', proxy.registrationInfo)
      const info = proxy.registrationInfo
      const { configSet } = proxy
      proxy.$refs.tobeReviewRules.validate().then(success => {
        if (success) {
          const params = {
            id: info.id,
            basic_info: {
              full_name: info.full_name,
              phone_number: info.phone_number,
              business_email: info.business_email,
              company_name: info.company_name,
              company_website: info.company_website,
              company_size: info.company_size,
              country: info.country,
              industry: info.industry,
              location: info.location,
              area_code: info.area_code,
              global_transaction_volume: info.global_transaction_volume,
              latam_transaction_volume: info.latam_transaction_volume,
            },
            region: info.region, // ['CHN', 'TWN']
            type: `${info.type}`,
            tag: `${info.tag}`,
            solution: info.solution,
            need_solution: info.need_solution.map(String),
            demand: info.demand,
            status: `${configSet.reviewStatus}`,
            // activation_status: configSet.activationStatus,
            activation_status: 1, // 不做选择，默认传1
            config_set: [
              { key: 'IPN_STATUS_FILTER', value: configSet.ipnStatus.join(',') },
              { key: 'BALANCE_VISIBLE', value: configSet.balanceVisible },
              { key: 'SETTLEMENT_GEN_BY_APP', value: configSet.appGenerates },
              { key: 'SETTLEMENT_TO_PAYOUT_BALANCE', value: configSet.payoutBalance },
              { key: 'SETTLEMENT_EXCHANGE_RATE', value: configSet.exchangeRate },
              { key: 'SETTLEMENT_CURRENCY', value: configSet.currency },
              { key: 'SETTLEMENT_METHOD', value: configSet.method },
            ],
            logo_url: info.logo_url,
            create_time: info.create_time,
            update_time: info.update_time,
          }
          if (configSet.method === 'A') {
            const cycle = configSet.generationCycle
            switch (cycle) {
              case 'D':// By Day
                params.config_set.push({ key: 'SETTLEMENT_REPORT_CYCLE', value: `${cycle},${configSet.cycleEveryDay}` })
                break
              case 'W':// By Week
                params.config_set.push({ key: 'SETTLEMENT_REPORT_CYCLE', value: `${cycle},${configSet.cycleEveryWeek},${configSet.cycleWeekday}` })
                break
              case 'M':// By Month
                params.config_set.push({ key: 'SETTLEMENT_REPORT_CYCLE', value: `${cycle},${configSet.cycleEveryMonth},${configSet.cycleMonthDay}` })
                break
              case 'A':// By Settleable Amount
                params.config_set.push({ key: 'SETTLEMENT_REPORT_CYCLE', value: `${cycle},${configSet.amountValue},${configSet.amountType},USD` })
                break
              default:
                break
            }
          }
          // 保存数据
          openAccountUpdate(params, () => {
            // 关闭sidebar
            proxy.$emit('confirm')
          })
        }
      })
    }
    return {
      logoUrl,
      inputImageRenderer,
      isToBeReviewedLoading,
      // options
      commonOptions,
      exchangeRateOptions,
      methodOptions,
      statusOptions,

      settlementReportsOption,
      weekdayOption,
      monthOption,
      amountOption,
      // func
      confirm,
      required,
    }
  },
  components: {
    BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox, vSelect, BImg, BFormInvalidFeedback, BInputGroup, ValidationProvider, ValidationObserver, DatePicker, BFormCheckboxGroup, BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    registrationInfo: {
      type: Object,
      default: () => {},
    },
    configSet: {
      type: Object,
      default: () => {},
    },
    // Review Status
    accountStatusList: {
      type: Array,
      default: () => [],
    },
    companySizeList: {
      type: Array,
      default: () => [],
    },
    currencyList: {
      type: Array,
      default: () => [],
    },
    industryList: {
      type: Array,
      default: () => [],
    },
    merchantTagList: {
      type: Array,
      default: () => [],
    },
    merchantTypeList: {
      type: Array,
      default: () => [],
    },
    phoneCodeList: {
      type: Array,
      default: () => [],
    },
    regionList: {
      type: Array,
      default: () => [],
    },
    solutionList: {
      type: Array,
      default: () => [],
    },
    // ipn
    tradeStatusList: {
      type: Array,
      default: () => [],
    },
    transactionVolumeList: {
      type: Array,
      default: () => [],
    },
  },
})

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
.generation-cycle {
  display: flex;
  justify-content: space-between;
  .v-select {
    flex: 1;
  }
  .input-group {
    width: 210px;
    margin-left: 20px;
  }
}
</style>
