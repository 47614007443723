import {
  ref, reactive,
  // watch,
  onMounted, getCurrentInstance,
} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment-timezone'
import registrationStoreModule from '../../registrationStoreModule'

export default function useRegistrationListView() {
  const REGISTRATION_STORE_MODULE_NAME = 'registration'
  // Register module
  if (!store.hasModule(REGISTRATION_STORE_MODULE_NAME)) store.registerModule(REGISTRATION_STORE_MODULE_NAME, registrationStoreModule)
  const { proxy } = getCurrentInstance()
  const isLoading = ref(false)
  // table
  const refRegistrationTable = ref('')
  const currentPage = ref(1)
  const perPage = ref(10)
  const totalRegistration = ref(0)
  const registrations = ref([])
  const dataMeta = reactive({
    from: 1,
    to: 1,
    of: 0,
  })
  const registrationTableFields = reactive([
    { label: 'Company Name', key: 'basic_info.company_name' },
    { label: 'Country', key: 'country' },
    { label: 'Contact Email', key: 'basic_info.business_email' },
    { label: 'Contact Phone', key: 'basic_info.phone_number' },
    { label: 'Create Time', key: 'create_time' },
    { label: 'Update Time', key: 'update_time' },
    { label: 'Review Status', key: 'review_status' },
    { label: 'Opened Solutions', key: 'solution' },
    { label: 'Action', key: 'action' },
  ])
  const reviewStatusFilter = ref('0')
  const paginationChange = value => {
    currentPage.value = value
  }
  const onFiltered = filteredItems => {
    totalRegistration.value = filteredItems.length
    currentPage.value = 1
  }

  // detail
  const registrationInfo = ref({})
  const currentReviewStatus = ref('')

  // other
  const tzSelected = ref('Etc/GMT+3')

  // func
  const recalMetaData = () => {
    dataMeta.from = currentPage.value * 10 - (10 - 1)
    dataMeta.to = totalRegistration.value - currentPage.value * 10 > 0 ? currentPage.value * 10 : totalRegistration.value
    dataMeta.of = totalRegistration.value
  }

  const fetchListRegistration = (vm, callback) => {
    isLoading.value = true
    store
      .dispatch('registration/fetchRegistrationList', {
        page_num: currentPage.value,
        page_size: perPage.value,
        data: {
          review_status: reviewStatusFilter.value,
        },
      })
      .then(response => {
        isLoading.value = false
        const { code, data } = response.data
        if (code === '10000') {
          callback(data.list)
          registrations.value = data.list
        }
        totalRegistration.value = data.total
        recalMetaData()
      }).catch(() => { isLoading.value = false })
      // .catch(() => {
      //   vm.$bvToast.toast('Error fetching Registration', {
      //     title: 'Error',
      //     icon: 'AlertTriangleIcon',
      //     variant: 'danger',
      //     toaster: 'b-toaster-top-right',
      //     solid: true,
      //   })
      // })
  }
  // watch([currentPage, perPage], () => {
  //   refRegistrationTable.value.refresh()
  // })
  const fastSelected = value => {
    reviewStatusFilter.value = value
    currentPage.value = 1
    refRegistrationTable.value.refresh()
  }
  // Dropdown List
  const accountStatusList = ref([])
  const companySizeList = ref([])
  const currencyList = ref([])
  const industryList = ref([])
  const merchantTagList = ref([])
  const merchantTypeList = ref([])
  const phoneCodeList = ref([])
  const regionList = ref([])
  const reviewStatusList = ref([])
  const solutionList = ref([])
  const tradeStatusList = ref([])
  const transactionVolumeList = ref([])
  const fetchDropdown = vm => {
    store
      .dispatch('registration/fetchDropdownList')
      .then(response => {
        accountStatusList.value = response.account_status_list
        companySizeList.value = response.company_size_list
        currencyList.value = response.currency_list
        industryList.value = response.industry_list
        merchantTagList.value = response.merchant_tag_list
        merchantTypeList.value = response.merchant_type_list
        phoneCodeList.value = response.phone_code_List
        regionList.value = response.region_list
        reviewStatusList.value = response.review_status_List
        solutionList.value = response.solution_list
        tradeStatusList.value = response.trade_status_list
        transactionVolumeList.value = response.transaction_volume_list
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching dropdown', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const dateFormat = value => {
    if (!value) {
      return ''
    }
    return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
  }
  const flatten = item => {
    if (!item) return
    Object.keys(item).forEach(m => {
      if (item[m].constructor === Object) {
        flatten(item[m])
      // } else if (item[m].constructor === Array) {
      //   const isChildObject = item[m].every(n => n.constructor === Object)
      //   // config_set处理
      //   if (isChildObject) {
      //     item[m].forEach(n => {
      //       registrationInfo.value[n.key] = registrationInfo.value[n.value]
      //     })
      //   } else {
      //     registrationInfo.value[m] = item[m]
      //   }
      } else {
        registrationInfo.value[m] = item[m]
      }
      if (m === 'config_set' && item[m] !== '') {
        item[m].forEach(n => {
          registrationInfo.value[n.key] = n.value
        })
      }
    })
  }
  const configSet = ref({
    ipnStatus: '',
    balanceVisible: 'no',
    appGenerates: 'yes',
    payoutBalance: 'yes',
    exchangeRate: 'Bank',
    currency: 'USD',
    method: 'M',
    reviewStatus: '',
    activationStatus: '',
    // cycle
    generationCycle: 'D',
    cycleEveryDay: '',
    cycleWeekday: '1',
    cycleEveryWeek: '',
    cycleMonthDay: '1',
    cycleEveryMonth: '',
    amountType: '0',
    amountValue: '',
  })
  const registrationDetail = item => {
    if (item.review_status === '1') {
      proxy.$refs['sidebar-registration-tobereviewed'].localShow = true
    } else if (item.review_status === '2') {
      proxy.$refs['sidebar-registration-reviewed'].localShow = true
    }

    if (proxy.$refs['tobereviewed-component'].$refs.tobeReviewRules) {
      proxy.$refs['tobereviewed-component'].$refs.tobeReviewRules.reset()
      proxy.$refs['tobereviewed-component'].logoUrl = ''
    }
    if (proxy.$refs['reviewed-component'].$refs.reviewRules) {
      proxy.$refs['reviewed-component'].$refs.reviewRules.reset()
      proxy.$refs['reviewed-component'].logoUrl = ''
    }

    // reset
    configSet.value.ipnStatus = ''
    configSet.value.balanceVisible = 'no'
    configSet.value.appGenerates = 'yes'
    configSet.value.payoutBalance = 'yes'
    configSet.value.exchangeRate = 'Bank'
    configSet.value.currency = 'USD'
    configSet.value.method = 'M'
    configSet.value.reviewStatus = ''
    configSet.value.activationStatus = ''
    // cycle
    configSet.value.generationCycle = 'D'
    configSet.value.cycleEveryDay = ''
    configSet.value.cycleWeekday = '1'
    configSet.value.cycleEveryWeek = ''
    configSet.value.cycleMonthDay = '1'
    configSet.value.cycleEveryMonth = ''
    configSet.value.amountType = '0'
    configSet.value.amountValue = ''

    registrationInfo.value = {}
    flatten(item)
    // 处理值为对象
    registrationInfo.value.industry = Number(registrationInfo.value.industry)
    if (registrationInfo.value.type !== '') {
      registrationInfo.value.type = Number(registrationInfo.value.type)
    }
    registrationInfo.value.need_solution = registrationInfo.value.need_solution.map(Number)
    if (registrationInfo.value.status !== '') {
      configSet.value.reviewStatus = Number(registrationInfo.value.status)
    }
    if (registrationInfo.value.area_code !== '') {
      registrationInfo.value.area_code = Number(registrationInfo.value.area_code)
    }

    // console.log('单条数据=', registrationInfo.value)
    // 待审核才处理
    if (registrationInfo.value.IPN_STATUS_FILTER && registrationInfo.value.review_status === '1') {
      configSet.value.ipnStatus = registrationInfo.value.IPN_STATUS_FILTER.split(',').map(Number)
      configSet.value.balanceVisible = registrationInfo.value.BALANCE_VISIBLE
      configSet.value.appGenerates = registrationInfo.value.SETTLEMENT_GEN_BY_APP
      configSet.value.payoutBalance = registrationInfo.value.SETTLEMENT_TO_PAYOUT_BALANCE
      configSet.value.exchangeRate = registrationInfo.value.SETTLEMENT_EXCHANGE_RATE
      configSet.value.currency = registrationInfo.value.SETTLEMENT_CURRENCY
      configSet.value.method = registrationInfo.value.SETTLEMENT_METHOD
      configSet.value.activationStatus = registrationInfo.value.activation_status
      if (registrationInfo.value.SETTLEMENT_REPORT_CYCLE) {
        const cycle = registrationInfo.value.SETTLEMENT_REPORT_CYCLE.split(',')
        // eslint-disable-next-line prefer-destructuring
        configSet.value.generationCycle = cycle[0]
        switch (cycle[0]) {
          case 'D':
          // eslint-disable-next-line prefer-destructuring
            configSet.value.cycleEveryDay = cycle[1]
            break
          case 'W':
          // eslint-disable-next-line prefer-destructuring
            configSet.value.cycleWeekday = cycle[2]
            // eslint-disable-next-line prefer-destructuring
            configSet.value.cycleEveryWeek = cycle[1]
            break
          case 'M':
          // eslint-disable-next-line prefer-destructuring
            configSet.value.cycleMonthDay = cycle[2]
            // eslint-disable-next-line prefer-destructuring
            configSet.value.cycleEveryMonth = cycle[1]
            break
          case 'A':
          // eslint-disable-next-line prefer-destructuring
            configSet.value.amountType = cycle[2]
            // eslint-disable-next-line prefer-destructuring
            configSet.value.amountValue = cycle[1]
            break
          default:
            break
        }
      }
    }
  }

  onMounted(() => {
    fetchDropdown()
  })

  return {
    isLoading,
    // table
    refRegistrationTable,
    currentPage,
    perPage,
    dataMeta,
    totalRegistration,
    // registration,
    registrationTableFields,
    fastSelected,
    reviewStatusFilter,
    paginationChange,
    onFiltered,
    registrations,
    // detail
    currentReviewStatus,
    tzSelected,
    registrationInfo,
    configSet,
    // func
    fetchListRegistration,
    dateFormat,
    registrationDetail,
    // Dropdown List
    accountStatusList,
    companySizeList,
    currencyList,
    industryList,
    merchantTagList,
    merchantTypeList,
    phoneCodeList,
    regionList,
    reviewStatusList,
    solutionList,
    tradeStatusList,
    transactionVolumeList,
  }
}
